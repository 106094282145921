.menu-items {
  background-color: #234983;
  color: #ffffff;
  font-weight: 600;
  min-width: 500px;
  float: left;
  font-size: 15px;
  float: right;
}

.menu-items.ant-menu-horizontal {
  border-bottom: 0px !important;
}

.menu-items.ant-menu-horizontal .ant-menu-item-selected,
.menu-items.ant-menu-horizontal .ant-menu-item-selected:hover,
.menu-items.ant-menu-horizontal .ant-menu-item-active:hover {
  color: #04b4c4;
}

.ant-menu-horizontal > .ant-menu-item-active::after,
.ant-menu-horizontal > .ant-menu-submenu-active::after {
  border-bottom: 3px solid #04b4c4 !important;
}

.bottom-nav .ant-tabs-bottom-bar {
  bottom: 0px !important;
  position: fixed !important;
  z-index: 2;
}

.bottom-nav .ant-tabs-content-holder {
  margin: -1px;
}

.bottom-nav .ant-tabs-tab {
  padding: 16px !important;
  margin: 1px !important;
  text-align: center !important;
}

.bottom-nav > .ant-tabs-bottom-content {
  margin-top: 64px !important;
}

.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
  width: 0px !important;
}

.bottom-nav > .ant-tabs-bottom-bar > .ant-tabs-nav-container {
  width: 100vw;
  display: flex;
  justify-content: space-between;
}
