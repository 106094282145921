.verification {
  height: 100vh;
}

.ant-carousel .slick-initialized .slick-slide {
  max-height: 240px;
}

.verifymobilenumber-otp-verification-form {
}
.select-before .ant-select-selection-item {
  margin-top: 5px !important;
  align-items: center !important;
  justify-content: center !important;
} 