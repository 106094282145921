@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

body {
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto", "Poppins", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@primary-color: #234983;@link-color: #F96900;@layout-header-background: #234983;