.comments-comment-list-item {
  background: #fff;
}

.comments-comment-input {
  display: block;
  text-align: end;
}

.comments-comment-input-actions {
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.comments-comment-input-close {
  color: #835f5c;
}

.comments-comment-input-save {
  margin-left: 10px;
  color: #234983;
}

.comments-comment-divider {
  font-size: 12px;
}

.comments-comment-list .ant-list-item {
  padding: 16px 14px;
}

.comments-comment .ant-comment-nested {
  margin-left: 0;
}

.comments-comment-list .ant-list-item .ant-list-item-action > li:first-child {
  display: block;
}

.comments-comment .ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.65);
}
.comments-comment .ant-comment-content-author-time {
  color: #979aa7;
  font-weight: 500;
}

.comments-comment-replies-collapse .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}

/* Use these classes to replicate comments style as in "Microsoft Teams" */
/* .comments-comment .ant-comment-avatar {
  margin-right: 30px; 
   margin-left: -60px;
}
.comments-comment-replies-collapse .ant-collapse-item > .ant-collapse-content {
  margin-left: 50px;
} */

.comments-comment-list-item .ant-collapse {
  box-shadow: none !important;
}
