.layout-header {
  padding: 0% 8%;
}

.layout-body {
  background-color: #f7f7f7;
  padding: 2% 8%;
  min-height: calc(100vh - 135px) !important;
  height: -o-calc(100vh - 135px) !important;
  height: -webkit-calc(100vh - 135px) !important;
  height: -moz-calc(100vh - 135px) !important;
}

.footer {
  background-color: #f7f7f7;
  text-align: center;
}
