.admin-stats-cards .ant-card-head {
  border-bottom: none;
  font-size: 15px;
  padding: 0px 24px;
  color: #333;
}

.admin-stats-cards .ant-card-body {
  height: 100px;
}

.days-activity {
  background: linear-gradient(135deg, #eeeeee 0%, #ffffff 100%) !important;
}
.revenues {
  background: linear-gradient(135deg, #eeeeee 0%, #ffffff 100%) !important;
}

.resolution-metrics {
  background: linear-gradient(135deg, #eeeeee 0%, #ffffff 100%) !important;
}

.device-health {
  background: linear-gradient(135deg, #eeeeee 0%, #ffffff 100%) !important;
}
