.pqqListItems {
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
}

.pqqListItems:last-child {
  border-bottom: none;
}

.applications-list-card .ant-card-body {
  padding: 0px;
  min-height: 70vh;
  max-height: 70vh;
  overflow-x: auto;
}
.applications-list {
  min-height: 70vh;
}

.applications-list p {
  margin-bottom: 0px;
  font-size: 12px;
  color: #7f7f7f;
  font-weight: 500;
}

.applications-list .ant-typography {
  font-size: 15px;
}

.applications-listitem {
  transition: all 0.2s ease-in-out;
  padding: 16px 24px !important;
}

.applications-listitem:hover {
  -webkit-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgba(174, 181, 246, 0.4), 0 1px 3px 1px rgba(174, 181, 246, 0.2);
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgba(174, 181, 246, 0.4), 0 1px 3px 1px rgba(174, 181, 246, 0.2);
  z-index: 1;
}

/* AdminApplicationsList */

.admin-applications-list .ant-table {
  min-height: 60vh;
}

.admin-applicaitons-list-check-circle {
  color: #3f8600;
}
.admin-applicaitons-list-close-circle {
  color: #cf1322;
}
