.admin-menu-logo,
.navigation-dropdown,
.menu-organization .org-change {
  cursor: pointer;
}

.admin-menu-dropdown {
  text-align: left;
  cursor: pointer;
}
