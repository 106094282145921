.privacyPolicy div.ant-typography,
.ant-typography p,
.privacyPolicy li {
  font-size: 15px;
  color: rgb(29, 21, 21);
}

.privacyPolicy {
  width: 100vw;
  padding: 50px 0px;
  background-color: #e7e7e7;
}

.privacy-policy-back {
  cursor: pointer;
  font-size: 16px;
}
