.menu-organization {
  font-size: 16px;
}

.menu-organization .active-org {
  color: #04b4c4;
  font-weight: 600;
}

.menu-organization .non-active-org {
  color: #ffffff;
  font-weight: 600;
}

.menu-organization .org-change {
  color: #b8b5b5 !important;
  font-size: 10px;
}

.menu-logo,
.navigation-dropdown,
.menu-organization .org-change {
  cursor: pointer;
}

.menu-dropdown {
  text-align: right;
  cursor: pointer;
}
