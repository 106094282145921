.pqq-details p {
  margin-bottom: 0px;
  font-size: 12px;
  color: #7f7f7f;
  font-weight: 500;
}

.pqq-details .ant-typography {
  font-size: 15px;
}

.pqq-details-select {
  min-width: 160px;
  border: 1px solid #234983;
  text-align: center;
}

.pqq-details-select-arrow .ant-select-arrow {
  color: #234983;
  font-size: 14px;
}

/* .ant-spin {
  position: fixed;
  z-index: 1031;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.pqq-comments-arrow-pulse {
  transition: 0.4s ease-in-out;
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(245, 245, 245, 0.2);
    transform: scale(1.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    transform: scale(1);
  }
}

.app-change {
  color: #00000073 !important;
  font-size: 12px;
  cursor: pointer;
}

.ant-descriptions-header {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
