.ant-form-item-control-input .form-submit-btn {
  width: 150px;
  margin-top: 20px;
}

.header-label {
  font-size: 13px;
  font-weight: 500;
}

.formitem-no-margin {
  margin-bottom: 8px;
}

.steps-container .multiline-form-item .ant-form-item-label {
  white-space: normal;
  display: inline-block;
  margin-right: 10px;
}

.steps-container .multiline-form-item .ant-form-item-label > label {
  display: inline-block;
}

.steps-container .ant-steps-item-title {
  font-size: 14px;
}
.ant-steps-navigation .ant-steps-item::before {
  background-color: #234983 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: #234983 !important;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.7);
}

.steps-container .ant-radio-wrapper {
  white-space: normal !important;
  padding: 0px !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #5cc431 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #5cc431 !important;
}

.ant-steps-vertical .ant-steps-item-content {
  min-height: 60px !important;
}

.ant-steps-item-active .ant-steps-item-title {
  color: #234983 !important;
  font-weight: bold;
}

.ant-picker-input > input[disabled] {
  color: rgb(0 0 0 / 85%);
}

.mandatory-field-mark::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
