.activity-feed-card {
  min-height: 60vh;
}

.activity-feed-card .ant-card-body,
.inventory-card .ant-card-body,
.request-for-access .ant-card-body {
  padding: 0px 24px 24px 24px;
  max-height: 370px;
  overflow-x: auto;
}

.activity-feed-list .ant-list-item-meta-description {
  color: #656262 !important;
  font-size: 13px;
}

.activity-feed-list .ant-list-item-action {
  margin-left: 24px;
}

.activity-feed-list .ant-list-item {
  border-bottom: none;
}
