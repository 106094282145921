.welcome-card {
  min-height: 18vh;
}

.welcome-card .ant-card-body {
  padding: 18px 16px 12px 16px;
}

.welcome-card:hover {
  background: linear-gradient(135deg, #f9de8d 0%, #ff9593 100%);
  color: #ffffff;
  cursor: pointer;
}

.welcome-card:hover .ant-typography {
  color: #ffffff;
}

.welcome-card .ant-statistic-content-suffix {
  margin-left: 0px;
  font-size: 12px;
}

.welcome-card .ant-statistic-content {
  font-size: 14px;
}
