.login {
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
    url("../../assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.login-form-button {
  font-weight: 600;
}

.footer-links {
  font-weight: 500;
  color: #f96900;
  cursor: pointer;
}

.login .ant-input-affix-wrapper {
  padding: 0px 6.5px !important;
  border-radius: 4px !important;
}
