body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-input-affix-wrapper {
  background: #f5f5f5 !important;
  padding: 0px 11px;
}

.ant-form-item-control-input .ant-input,
.ant-form-item-control-input .ant-select .ant-select-selector,
.ant-form-item-control-input .ant-input-number,
.ant-form-item-control-input .ant-picker {
  min-height: 45px !important;
  border-radius: 4px !important;
  background: #f5f5f5 !important;
}

.ant-btn,
.ant-radio-button-wrapper {
  min-height: 40px !important;
  border-radius: 4px !important;
}

.ant-radio-button-wrapper {
  line-height: 40px !important;
}

.ant-collapse {
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}
