.default-card {
  border-radius: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.default-card .ant-card-head {
  border-bottom: none !important;
}

.default-card .ant-card-body {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.default-card .ant-card-body::-webkit-scrollbar {
  display: none;
}

input[type="range"] {
  box-shadow: none !important;
}
