.users-list-check-circle {
  color: #3f8600;
  font-size: 18px;
}

.users-list-close-circle {
  color: #cf1322;
  font-size: 18px;
}

.users-table .ant-table {
  min-height: 60vh;
}

/* .users-table {
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
} */

.users-verified-icon {
  color: #a5a5a5;
  /* color: #3f8600; */
  font-size: 20px;
  /* margin: 5px; */
}

.users-not-verified-icon {
  color: #a5a5a5;
  font-size: 20px;
}

/*  */
.add-vendor.ant-switch-checked {
  background-color: #87d068;
}
