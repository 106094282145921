.admin-applications-list .ant-table {
  min-height: 60vh;
}

.admin-applicaitons-list-check-circle {
  color: #3f8600;
}
.admin-applicaitons-list-close-circle {
  color: #cf1322;
}

/* .admin-applications-list {
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
} */
